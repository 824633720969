import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import _get from 'lodash/get';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { CURRENCY } from 'constants/Constants';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';
import cartTools from 'tools/cart';

import { showNotification } from 'actions/NotificationsActions';
import { showPlayer } from 'actions/PlayerActions';
import { placeQuote } from 'actions/UserActions';

import PlayerCover from 'components/Cover/PlayerCover';
import { LoadingSmall } from 'components/Loading';
import NewFlow from 'components/NewFlow';

import CommunSVGs from 'svgs/commun';

type Props = {
  pack: Object,
  artwork: Object,
  userProfile: Object,
  className: String,
};

const Header = ({ pack, artwork, userProfile, className }: Props) => {
  const { t } = useTranslation('pack');
  const userCart = useSelector(state => state.user.cart);
  const visitorPackAccesses = useSelector(state => state.user.visitorPackAccesses);
  const isFetching = useSelector(state => state.artwork?.isFetching?.getArtworkById);

  const artworkPack = artwork?.packs?.find(p => p.id === parseInt(pack.id, 10));

  const [purchased, setPurchased] = useState(!!artworkPack?.purchased);
  const history = useHistory();

  // actions
  const dispatch = useDispatch();

  useEffect(() => {
    if (artworkPack) {
      setPurchased(!!artworkPack?.purchased);
    }
  }, [artworkPack]);

  if (!artwork || !artworkPack) {
    return <LoadingSmall />;
  }

  const buy = () => {
    const quote = cartTools.getQuoteFromCart(userCart);
    if (quote.packs?.indexOf(artworkPack.id) < 0) {
      quote.packs.push(artworkPack.id);
      dispatch(placeQuote(quote, { packId: artworkPack.id, name: artworkPack.name }, artwork));
    } else {
      dispatch(
        showNotification({
          level: 'info',
          title: 'Already in your cart.',
        }),
      );
    }
  };

  let filesCount = 'some';
  if (pack.totalFiles || pack.totalFiles === 0) filesCount = pack.totalFiles;
  else if (pack.files) filesCount = pack.files.length;
  else if (pack.id && pack[pack.id]) filesCount = pack[pack.id].length;

  const username = artwork?.author?.username || userProfile.username;
  const isFree = !artworkPack.price || artworkPack.price <= 0;
  const isFollowed = _get(artwork, 'artworkAccess.isFollower');

  const visitorPackAccess = visitorPackAccesses?.find(p => p.id === pack.id);
  const lastFileRead = pack.lastFileRead || artworkPack.lastFileRead || visitorPackAccess?.lastFileRead?.id;
  const playerParams = {
    type: 'pack',
    id: pack.id,
    fileId: lastFileRead?.id || lastFileRead,
    artworkId: artworkUtils.getEntityId(artwork),
  };

  return (
    <div className={`d-flex flex-nowrap ${className || ''}`}>
      <div className="Folder-header">
        <div className="Pack-cover-wrapper">
          {isFollowed && pack.unreadCount > 0 && (
            <div className="Pack-cover-badge">{pack.unreadCount > 9 ? '9+' : pack.unreadCount}</div>
          )}
          {isFollowed && pack.isNew && <NewFlow className="Pack-cover-badge" />}
          <PlayerCover
            src={artworkUtils.getCoverUrl(artworkPack, 57)}
            alt={artworkPack.name}
            className="Folder-cover"
            playerParams={playerParams}
            // overlay={<CommunSVGs.PlayMe width="50" height="50" fill="var(--primary)" />}
            artwork={artwork}
          />
        </div>
        <div className="Folder-titles">
          <h2
            className="h5 Folder-title flowfont-title text-secondary cursor"
            onClick={() => dispatch(showPlayer(playerParams, artwork))}
            role="presentation"
          >
            {artworkPack.name}
          </h2>
          <Link to={`/artwork/${username}/${artwork.uniqueName}/pack/${artworkPack.id}`} className="Pack-link fs-09">
            <p className="Folder-subtitle">
              <span>{t('files-number', { count: filesCount })}</span>
            </p>
          </Link>
          <div>
            {!!pack.readCountAvg && (
              <span className="text-neutralSubtle flowfont-regular mr-2">
                <OverlayTrigger
                  key="readfull"
                  placement="bottom"
                  trigger={['hover', 'focus']}
                  delay={{ show: 500 }}
                  overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-readers')}</Tooltip>}
                >
                  <span className="d-inline-block">
                    {artworkUtils.formatCount(pack.readCountAvg)}{' '}
                    <CommunSVGs.Readfull width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                  </span>
                </OverlayTrigger>
              </span>
            )}
            {!!pack.feedbackCount && (
              <span className="text-neutralSubtle flowfont-regular mr-2">
                <OverlayTrigger
                  key="readfull"
                  placement="bottom"
                  trigger={['hover', 'focus']}
                  delay={{ show: 500 }}
                  overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-reactions')}</Tooltip>}
                >
                  <span className="d-inline-block">
                    {artworkUtils.formatCount(pack.feedbackCount)}{' '}
                    <CommunSVGs.NiceWork width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                  </span>
                </OverlayTrigger>
              </span>
            )}
            {!!pack.commentCount && (
              <span className="text-neutralSubtle flowfont-regular">
                <OverlayTrigger
                  key="readfull"
                  placement="bottom"
                  trigger={['hover', 'focus']}
                  delay={{ show: 500 }}
                  overlay={<Tooltip className="FolderFile-tooltipBlue">{t('folder:tip-comments')}</Tooltip>}
                >
                  <span className="d-inline-block">
                    {artworkUtils.formatCount(pack.commentCount)}{' '}
                    <CommunSVGs.Bubble width="16" color="var(--neutralSubtle)" transform="translate(0,-2)" />
                  </span>
                </OverlayTrigger>
              </span>
            )}
          </div>
        </div>
        <div className="Folder-back" onClick={history.goBack} role="presentation">
          <CommunSVGs.ArrowLeft width="25px" height="25px" fill="#EDEDED" />
        </div>
      </div>
      <div className="Folder-actions text-center">
        {acl.isAuthor(userProfile, artwork) ? (
          <Link to={`/pack/edit/${pack.id}`} className="btn btn-sm btn-outline-secondary rounded-pill Folder-button">
            {t('settings-button')}
          </Link>
        ) : (
          <>
            {isFetching && <LoadingSmall />}
            {!isFetching && !purchased && !isFree && (
              <Button
                className="Folder-button rounded-pill"
                id="FlowfoButtonBuy"
                variant="outline-secondary"
                size="sm"
                onClick={buy}
              >
                {!isFree && t('global:buy')}
                {isFree && t('global:save')}
              </Button>
            )}
            {!isFetching && purchased && !isFree && (
              <Button className="Folder-button" variant="neutral rounded-pill" size="sm" disabled>
                {!isFree && t('global:bought')}
                {isFree && t('global:saved')}
              </Button>
            )}
          </>
        )}
        <div className="Folder-price text-neutral flowfont-medium">
          {!isFree && `${artworkPack.price} ${CURRENCY}`}
          <p className="text-neutralSubtle flowfont-medium fs-09">{!isFree && t('vat')}</p>
          {isFree && <span className="flowfont-medium">{t('free-flow')}</span>}
        </div>
      </div>
    </div>
  );
};

export default Header;
