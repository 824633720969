import loadable from '@loadable/component';

import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import _get from 'lodash/get';

import { getPackFiles, markAsReadPack } from 'actions/PackActions';

import './Folder.scss';
import PackHeader from './Pack/Header';

const PackContent = loadable(() => import('./Pack/Content'));

type Props = {
  artwork: Object,
  pack: Object,
  isFull: Boolean,
  userProfile: Object,
  className: Object,
};

const PackFolder = ({ pack, artwork, isFull, userProfile, className }: Props) => {
  // store selectors
  const files = useSelector(state => _get(state.pack, `[${pack.id}]`));
  // actions
  const dispatch = useDispatch();
  // componentDidMount
  useEffect(() => {
    if (pack && isFull) {
      dispatch(getPackFiles(pack.id, 1, 4));
    }
  }, [pack, isFull, dispatch]);

  const handleMarkAsRead = () => {
    dispatch(markAsReadPack(pack.id, artwork.id));
  };

  const data = { ...pack, files };

  return (
    <div className={`${className} Folder`}>
      <PackHeader pack={data} artwork={artwork} isFull={isFull} userProfile={userProfile} />
      {isFull && (
        <PackContent
          pack={data}
          isFollowed={_get(artwork, 'artworkAccess.isFollower')}
          userProfile={userProfile}
          onMarkAsRead={handleMarkAsRead}
        />
      )}
    </div>
  );
};

export default React.memo(PackFolder);
