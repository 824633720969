import React from 'react';

import CommunSVGs from 'svgs/commun';

import './NewFlow.scss';

type Props = {
  className: String,
};

const NewFlow = ({ className }: Props) => {
  return (
    <div className={`NewFlow ${className || ''}`}>
      <CommunSVGs.NewFlow width="16" height="16" fill="white" />
      <span className="NewFlow-text"> Flow</span>
    </div>
  );
};

export default NewFlow;
